<template>
    <div>
        <BaseInfo :baseInfo="$store.state.user.baseInfo" />
        <NavBar
                title="问卷列表"
                left-arrow
                @click-left="$router.go(-1)"
        />
        <div style="margin: 16px;">
            <Button round block type="info" @click="addDiary">添加健康日记</Button>
        </div>
        <Divider v-if="!is_diary"/>
        <Collapse v-model="activeName" accordion>
            <div v-for="(val,key) in typeHash" :key="key">

                <CollapseItem :title="val" :name="val">
                    <cell-group>
                        <cell v-for="(idx,index) in crfsHash[key][1]" :title="idx.crftitle" :key="index"
                              is-link @click="showCrf(idx)"  >
                            <template #label  >
                                <!--                    <div class="title"  >{{idx.send_title}}</div>-->
                                <div class="is_completed" v-if="idx.id">填写时间：{{parseTime(idx.time,'{y}-{m}-{d}')}}</div>
                            </template>
                            <!--                <template   >-->
                            <!--                    <span class="is_completed" v-if="idx.sf_status==1" >已完成</span>-->
                            <!--                    <span class="is_uncompleted" v-if="idx.sf_status==0" >未完成</span>-->
                            <!--                </template>-->
                        </cell>
                    </cell-group>

                </CollapseItem>
            </div>
        </Collapse>

        <empty v-if="nodata&&!user_empty" description="查无数据" />
        <Loading type="spinner" color="#1989fa" size="24px" vertical v-if="user_empty" style="margin-top: 40%">获取授权中...</Loading>

        <RLFooter/>
    </div>
</template>

<script>

    import {Cell,Empty,CellGroup,Loading,Button,Divider,Collapse, CollapseItem } from 'vant';
    import {getDiarys} from '@/api/crfs';
    // import {getBaseInfo} from '@/api/patient/baseinfo';
    import {setToken, removeToken, getToken} from '@/utils/auth';
    import {getUser} from '@/api/user';

    export default {
        name: 'QueIdx',
        components: {
            Cell,Empty,CellGroup,Loading,Button,Divider,Collapse, CollapseItem
        },
        data(){
            return {
                active:0,
                followcrfs:[],
                nodata:true,
                user:{
                    userName:'',
                    nickName:'',
                    idCard:'',
                    phone:'',
                    gender:'1',
                    birthday:'',
                    id:'',
                    pid:''
                },
                user_empty:true,
                follow_time:null,
                is_diary:true,
                typeHash:{},
                crfsHash:{},
                activeName:'-1'

            };
        },
        computed:{

        },
        methods:{
            showCrf(idx){
                // this.$router.push({path: '/followcrf/'+idx.id});
              let query = this.$route.query;
              let patientid=query.patientid;
                this.$router.push({path: '/crf/',query:{crfname:idx.crfname,sendid:null,itemdataorderid:idx.id,follow_time:null,patientid:patientid}});
            },
            formaterState(fins){
                if(fins==1){
                    return '未完成';
                }else{
                    return '已完成';
                }
            },
            formaterTime(ctime,uptime){
                let res="";
                if(ctime){
                    res+="创建时间："+this.parseTime(ctime);
                }
                if(uptime){
                    res+="    更新时间："+this.parseTime(uptime);
                }
                return res;
            },
            addDiary(){
              let query = this.$route.query;
              let patientid=query.patientid;
                this.$router.push({path: '/crf/',query:{crfname:'crf-zsyy-0007',sendid:null,itemdataorderid:null,follow_time:null,is_type:"1",patientid:patientid}});
            },

            initData(){
                // let query = this.$route.query;
                // this.follow_time=query.followtime;
                getDiarys({patientid:this.user.id}).then((response) => {
                    console.log(response)
                    if(response.code==200 ){
                        // this.loading=true;
                        this.active=response.data.length;

                        this.typeHash=response.data.reDate;
                        this.crfsHash=response.data.data;
                        console.log(response.data)
                        console.log(this.typeHash)
                        console.log(this.crfsHash)
                        for(let key in this.typeHash){
                            this.activeName = this.typeHash[key]
                            break;
                        }
                        // try {
                        //
                        //     this.typeHash.forEach((value, key)=>{
                        //         console.log(value,key);
                        //         this.activeName = key
                        //         throw new Error("LoopTerminates");
                        //     });
                        // } catch (e) {
                        //     if (e.message !== "LoopTerminates") throw e;
                        // };

                        // this.activeName = this.typeHash
                        this.nodata = false

                    }
                });
                // let follow_time=this.parseTime(new Date(),'{y}-{m}-{d}');

            },
        }
        ,
        created() {
          // 开发环境
          if(process.env.NODE_ENV=='development'){
            let query = this.$route.query;
            let patientid=query.patientid;
            console.log(patientid)
            getUser({patientid:patientid}).then((res) => {
              console.info(res.data);

              if(res.data){
                this.$store.commit('updateOpenid',res.data.openid);
                this.$store.commit('updatePid',res.data.id);
                this.$store.commit('updateBaseInfo',res.data);
                if(res.data.name !=''){
                  this.substatus = true
                }
                this.user.id=res.data.id;
                this.user.name=res.data.name;
                this.user.birthday=res.data.birthday;
                // this.user.idCard=response.data.idCard;
                this.user.telephone=res.data.telephone;
                this.user.gender=res.data.gender+'';
                this.user.address=res.data.address;
                this.user.detail_address=res.data.detail_address;
                // this.initBaseInfo();
                this.user_empty = false

                this.initData();

              }
            });
          }else{
            //生产环境
            let query = this.$route.query;
            let codeTemp = getToken();
            if(query.code || this.getQueryString('code') || query.patientid ||codeTemp){
              if(query.code || this.getQueryString('code')){
                this.$store.commit('updateCode',query.code?query.code : this.getQueryString('code'));
                setToken(query.code?query.code : this.getQueryString('code'));
              }
              // let query = this.$route.query;
              let patientid=query.patientid;
              console.log(patientid)
              getUser({patientid:patientid}).then((res) => {
                console.info(res.data);

                if(res.data){
                  this.$store.commit('updateOpenid',res.data.openid);
                  this.$store.commit('updatePid',res.data.id);
                  this.$store.commit('updateBaseInfo',res.data);
                  if(res.data.name !=''){
                    this.substatus = true
                  }
                  this.user.id=res.data.id;
                  this.user.name=res.data.name;
                  this.user.birthday=res.data.birthday;
                  // this.user.idCard=response.data.idCard;
                  this.user.telephone=res.data.telephone;
                  this.user.gender=res.data.gender+'';
                  this.user.address=res.data.address;
                  this.user.detail_address=res.data.detail_address;
                  // this.initBaseInfo();
                  this.user_empty = false

                  this.initData();

                }
              });
              //getBaseInfo({}).then((response) => {
              //   console.info(response);
              // });
            }else{
              removeToken();
            }
          }


            // this.initBaseInfo();
            /*初始化列表*/
            // this.initidx();

        },
    }
</script>

<style scoped>

    .is_completed{
        color: #1bde1a;
        /*color: aqua;*/
    }
    .is_uncompleted{
        color:red;
    }

</style>
